import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

const IframePage = ({ pageContext }) => {
  const [list, setList] = useState([]);
  const trustedOrigins = ['http://localhost:8000', 'https://front-dev.colas-corpo.monkees.pro', 'https://staging.colas-corpo.monkees.pro'];
  useEffect(() => {
    window.addEventListener('message', onMsg, false);
  }, []);
  const onMsg = (msg) => {
    if (!trustedOrigins.includes(msg.origin)) return;
    const data_received = JSON.parse(msg.data);
    if (data_received.message.length > 0) {
      setList(data_received.message);
    } else {
      setList([]);
    }
  };

  return (
    <div style={{ border: '1px solid red' }}>
      <p style={{ margin: '0', padding: '0' }}>iframe page</p>
      <ul style={{ margin: '0', padding: '0' }}>
        {
          list.length > 0 && (
            list.map((singleData, index) => (
              <li style={{ marginBottom: '5px' }} key={index}>
                {singleData.title} - Distance - {singleData.distance_from_geoloc}
              </li>
            ))
          )
        }
      </ul>
    </div>
  );
};

IframePage.propTypes = {
  pageContext: PropTypes.object.isRequired
};

export default IframePage;
